const DEV_MODE =  false
const PROD_MODE = false

const API_BASE =    DEV_MODE ? 'http://localhost:8081/' : PROD_MODE ? 'https://vestibular-back.faculdadereboucas.com.br/' :'https://vestibular-back-dev.faculdadereboucas.com.br/'
const IMAGE_BASE =  DEV_MODE  ? 'http://localhost:8081/uploads/': PROD_MODE ? 'https://vestibular-back.faculdadereboucas.com.br/uploads/' :'https://vestibular-back-dev.faculdadereboucas.com.br/uploads/'

const REACT_IMAGE_BASE = DEV_MODE ? 'http://localhost:3011/' : PROD_MODE ? 'https://vestibular.faculdadereboucas.com.br/' : 'https://vestibular-dev.faculdadereboucas.com.br/'


const PAGARME_SECRET_KEY = PROD_MODE ? 'sk_eb4b3c52f8a842d5a5f7db77772b9e6d' : 'sk_test_2aee8f79680b431593c0b68d14080da1'


export {API_BASE, IMAGE_BASE, REACT_IMAGE_BASE, DEV_MODE, PROD_MODE, PAGARME_SECRET_KEY}