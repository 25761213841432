/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState,useEffect} from 'react'
import {Link} from 'react-router-dom'

import API from '../../../../API_CONFIGS/redux/API'
import { useNavigate } from 'react-router-dom'
import {toast} from 'react-toastify'
import { cpf } from 'cpf-cnpj-validator'; 

// import { loginAuth } from '../../../../AUTH_CONFIGS/Auth'
import {useAuth} from '../../../../AUTH_CONFIGS/Auth'

import Loading from '../../../components/loading/Loading'


export function Login() {
  const navigate = useNavigate()
  const auth = useAuth();

  const {currentUser} = useAuth()

  useEffect(()=>{
    if(currentUser && currentUser.id){
      return navigate('/vestibular') 
    }
    return
  },[currentUser])

  const [email,setEmail] = useState('')
  const [password,setPassword] = useState('')

  const [validEmail,setValidEmail]= useState(false)
  const [cpfValid,setCpfValid] = useState(true)

  const [loading,setLoading]=useState(false)


  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }
  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    // if(e.target.value.length<=11){
      setPassword(e.target.value);
    // }
  };
  const handleLogin = async () => {
    setLoading(true)
    try {
      await auth.login(email, password)
      setLoading(false)
      navigate('/vestibular')
    } catch (err) {
      setLoading(false)
      toast.error('Erro ao logar. Contate o suporte.')
      console.error(err)
    }
  };
  
  const[loginReady,setloginReady]=useState(false)
 
  useEffect(()=>{
    const emailRegex = /^[^\s@]+@[^\s@]+\.(?:co|com|br)$/i;
    if(emailRegex.test(email)){
      setValidEmail(true)
    }
    else{
      setValidEmail(false)
    }



    let minhaString = password
    minhaString = minhaString.replace(/[ .-]/g, "");
    let isValidAux = false
    isValidAux = cpf.isValid(minhaString)
    if (isValidAux) {
      // setCpfValid(true);
    } 
    else {
      // setCpfValid(false);
    }



  },[password,email])


  useEffect(()=>{
    if(validEmail && cpfValid){
      setloginReady(true)
    }
    else{
      setloginReady(false)
    }
  },[validEmail,cpfValid])


  return (

    <>
    
    {loading && 
        <div style={{position:'absolute', left:'0', top: '0', zIndex:'9999999999999'}}>
          <Loading active={loading} text={'Carregando'} />
        </div>
    }



    <div
      className='card'>


      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Portal do Candidato</h1>
        <div className='text-gray-500 fw-semibold fs-6'>Digite seu e-mail e senha</div>
      </div>
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark required'>Email</label>
        <input
          placeholder='Digite seu Email'
          className={`form-control bg-transparent ${email.length>0 && !validEmail && 'is-invalid'} ${email.length>0 && validEmail && 'is-valid'}`}
          type='text'
          name='email'
          autoComplete='off'
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEmail(e)}
          />
         {!validEmail && email.length>0&&
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>O Email informado não é válido</span>
              </div>
            </div>
          }

       
      </div>
      <div className='fv-row mb-3'>
  <label htmlFor='password' className='form-label fw-bolder text-dark fs-6 mb-0 required'>Senha</label>
  <input
    type='password'
    id='password'
    name='password'
    autoComplete='off'
    className={`form-control bg-transparent 
    `}
    // ${password.length===11 && !cpfValid && 'is-invalid'} ${password.length===11 && cpfValid && 'is-valid'}
    placeholder='Digite sua senha' 
    value={password}
    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePassword(e)}
    />
  {/* {!cpfValid && password.length === 11&&
    <div className='fv-plugins-message-container'>
      <div className='fv-help-block'>
        <span role='alert'>O CPF informado não é válido</span>
      </div>
    </div>
  } */}

</div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        {/* <Link to='/esqueci-minha-senha' className='link-primary'>Esqueci minha senha</Link> */}
        {/* end::Link */}

      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='button'
          className='btn btn-primary'
          disabled={!loginReady}
          onClick={()=>{handleLogin()}}
        >
          <span className='indicator-label'>Continue</span>
                   
        </button>
      </div>
      {/* end::Action */}

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Vem estudar com a gente!{' '}
        <Link to='/registro' className='link-primary'>Fazer vestibular</Link>
      </div>
    </div>
</>
  )
}
